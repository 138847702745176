%container {
  width: 100%;
  max-width: rem-calc(1920);
  margin-right: auto;
  margin-left: auto;
}

// %heading-1,
// %heading-2 {
// font-family: $font-montserrat;
// color: $color-gray-300;
// line-height: 1.2;
// transition: all 250ms $ease-stn;
// }S

// %heading-1 {
// }

// %heading-2 {
// }

%title-filter {
  span {
    font-weight: 900;
  }
}
