.footer {
  width: 100%;
  display: flex;
  background-color: #1a1a1a;
  height: auto;
  justify-content: center;
  text-align: center;
  z-index: 10;

  @include media(">=phone") {
    height: 120px;
  }
  @include media(">=phone-medium") {
    height: 120px;
  }
  @include media("<=phone-xsmall") {
    height: 150px;
    padding-top: 30px;
  }
  @include media(">=ipad-air") {
    height: auto;
  }
  @include media(">=ipad-mini") {
    height: auto;
    
  }
  @include media(">=desktop-small") {
    height: auto;
    padding: 10px;
  }
  @include media(">=desktop-large") {
    height: auto;
    padding: 10px;
  }
  @include media(">=desktop-xlarge") {
    height: auto;
    padding: 10px;
  }
  @include media(">=tablet-small") {
    height: auto;
    padding: 10px;
  }
  @include media("<=tablet"){
    height: 140px;
    padding: 10px;
  }
}

.footerText {
  font-size: 13px;
  color: #f8f5e3;
  a {
    color: #f8f5e3;
    margin-left: 5px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
