//
// Neue Machina
//

@font-face {
	font-family: 'Neue Machina';
	src: url('../../assets/font/Neue-Machina/NeueMachina-Regular.otf');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('../../assets/font/Neue-Machina/NeueMachina-Light.otf');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('../../assets/font/Neue-Machina/NeueMachina-Ultrabold.otf');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face{
	font-family: 'Bingo Bold Trial';
	src: url('../../assets//font/Bingo-Bold-Trial/ABCBingo-Bold-Trial.otf'); 
	font-style: normal;
	font-display: swap;
}