.counter {
  display: flex;
  flex-direction: column;

  @include media(">=phone-medium") {
  }

  &__title {
    font-size: 30px;
    font-family: $font-bingo-bold-trial;
    font-weight: 900;
    color: $color-white-100;

    @include media(">=phone-xsmall") {
      font-size: fluid("phone-xsmall", "phone-medium", 18px, 24px);
    }

    @include media(">=phone-medium") {
      font-size: fluid("phone-medium", "phone", 24px, 28px);
    }

    @include media(">=phone") {
      font-size: rem-calc(22);
    }

    @include media(">=desktop-small") {
      font-size: 50px;
    }

    @include media(">=desktop-xlarge") {
      font-size: rem-calc(35);
    }
  }

  .countdown {
    width: 95%;
    margin-top: rem-calc(25);
    margin-right: auto;
    margin-left: auto;

    @include media(">=phone-xsmall") {
      margin-top: fluid("phone-xsmall", "phone-medium", 25px, 35px);
    }

    @include media(">=phone-medium") {
      margin-top: rem-calc(35);
    }

    @include media(">=phone") {
      width: 90%;
      margin-top: rem-calc(18);
      margin-left: 0;
    }

    @include media(">=tablet") {
      width: 70%;
    }

    @include media(">=desktop-small") {
      width: 100%;
      max-width: fluid("desktop-small", "desktop-xlarge", 275px, 450px);
      margin-top: fluid("desktop-small", "desktop-xlarge", 18px, 25px);
    }

    @include media(">=desktop-xlarge") {
      max-width: rem-calc(450);
      margin-top: rem-calc(25);
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__item-value,
    &__item-label {
      display: block;
      font-family: $font-bingo-bold-trial;
      font-weight: 300;
      text-align: center;
      color: $color-white-100;
    }

    &__item-value {
      font-size: rem-calc(25);

      @include media(">=phone-xsmall") {
        font-size: fluid("phone-xsmall", "phone-medium", 25px, 30px);
      }

      @include media(">=phone-medium") {
        font-size: fluid("phone-medium", "phone", 30px, 34px);
      }

      @include media(">=phone") {
        font-size: rem-calc(25);
      }

      @include media(">=desktop-small") {
        font-size: 50px;
      }

      @include media(">=desktop-xlarge") {
        font-size: rem-calc(42);
      }
    }

    &__item-label {
      margin-top: rem-calc(8);
      font-size: rem-calc(14);

      @include media(">=phone") {
        font-size: rem-calc(12);
      }

      @include media(">=desktop-small") {
        margin-top: fluid("desktop-small", "desktop-xlarge", 10px, 20px);
        font-size: rem-calc(14);
      }

      @include media(">=desktop-xlarge") {
        margin-top: rem-calc(20);
      }
    }
  }

  &__cta {
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: 10px;

    @include media(">=phone-xsmall") {
    }

    @include media(">=phone-medium") {
    }

    @include media(">=phone") {
    }

    @include media(">=desktop-small") {
    }

    @include media(">=desktop-xlarge") {
    }
  }

  .btn__top {
    background-color: $color-red2-100;
    box-shadow: inset -1px 0px 3px black;
    height: 7px;
    margin: 0;
    border-radius: 2px;
  }

  .container__btn {
    margin-top: 30px;
  }
}
