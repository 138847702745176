.counter-banner .counter-form {
    margin-top: rem-calc(35);

    @include media('>=phone-xsmall') {
        margin-top: fluid('phone-xsmall', 'phone-medium', 35px, 60px);
    }

    @include media('>=phone-medium') {
        margin-top: rem-calc(60);
    }

    @include media('>=phone') {
        margin-top: rem-calc(35);
    }

    @include media('>=desktop-small') {
        margin-top: fluid('desktop-small', 'desktop-xlarge', 35px, 45px);
    }

    @include media('>=desktop-xlarge') {
        margin-top: rem-calc(45);
    }

    &__label {
        display: flex;
        justify-content: center;
        font-size: rem-calc(20);
        font-family: $font-bingo-bold-trial;
        font-weight: 300;
        color: $color-white-100;

        @include media('>=phone-xsmall') {
           
        }
    
        @include media('>=phone-medium') {
            
            font-size: fluid('phone-medium', 'phone', 24px, 28px);
        }

        @include media('>=phone') {
            font-size: rem-calc(18);
        }

        @include media('>=desktop-small') {
            font-size: fluid('desktop-small', 'desktop-xlarge', 18px, 30px);
            display: block;
        }

        @include media('>=desktop-xlarge') {
            font-size: rem-calc(30);
            display: block;
        }
        @include media('>=ipad-air') {
            font-size: rem-calc(15);
            display: block;
        }
        @include media('>=ipad-mini') {
            font-size: rem-calc(15);
            display: block;
        }
    }

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: rem-calc(16);
        margin-bottom: 10px;

        @include media('>=phone-xsmall') {
            margin-top: fluid('phone-xsmall', 'phone-medium', 16px, 22px);
        }
    
        @include media('>=phone-medium') {
            margin-top: rem-calc(22);
        }

        @include media('>=phone') {
            align-items: flex-start;
            margin-top: rem-calc(10);
        }

        @include media('>=tablet') {
            flex-direction: row;
            gap: rem-calc(10);
        }

        @include media('>=desktop-small') {
            margin-top: fluid('desktop-small', 'desktop-xlarge', 10px, 20px);
        }

        @include media('>=desktop-medium') {
            gap: rem-calc(15);
        }

        @include media('>=desktop-large') {
            gap: rem-calc(18);
        }

        @include media('>=desktop-xlarge') {
            margin-top: rem-calc(20);
        }

        &__input {
            width: 100%;
            font-size: rem-calc(16);
            font-family: $font-bingo-bold-trial;
            text-transform: uppercase;
            border: none;
            color: $color-gray-100;
            background-color: $color-blue-100;
            margin-bottom: 20px;
            @include media('>=phone') {
                font-size: rem-calc(14);
            }

            @include media('>=tablet') {
                max-width: fluid('tablet', 'desktop-mlarge', 280px, 330px);
                min-height: rem-calc(45);
            }

            @include media('>=desktop-small') {
                min-height: fluid('desktop-small', 'desktop-xlarge', 10px, 62px);
                height: 35px;
                border-radius: 3px;

            }
        
            @include media('>=desktop-mlarge') {
                max-width: rem-calc(330);
                font-size: rem-calc(18);
                height: 30px;

            }

            @include media('>=desktop-xlarge') {
                min-height: rem-calc(80);
                height: 30px;
            }
            @include media('>=ipad-mini') {
                min-height: rem-calc(35);
                border-radius: 10px;





            }

        }

        &__submit {
            width: 100%;
            @include media('>=phone-xsmall') {
                margin-top: fluid('phone-xsmall', 'phone-medium', 20px, 28px);
            }
        
            @include media('>=phone-medium') {
                margin-top: rem-calc(28);
            }

            @include media('>=phone') {
                margin-top: 50px;
            }

            @include media('>=tablet') {
                margin-top: 0;
            }

            @include media('>=desktop-small') {
            }
        }
    }
}