.appear {
	&--fade-in {
		transition: opacity 750ms;

		&:not(.animate-appear) {
			opacity: 0;
		}
	}

	&--fade-in-up {
		transition: opacity 750ms, transform 750ms $ease-dec;

		&:not(.animate-appear) {
			opacity: 0;
			transform: translateY(10px);
		}
	}
}

@keyframes fadeup {
	0% {
		opacity: 0;
		transform: translateY(#{rem-calc(30)});
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

