.about {
  background-color: $color-purple-100;
  padding: 10px;
  &__wrapper {
    @extend %container;

    position: relative;
    width: 85%;
    padding: rem-calc(170) 0 rem-calc(270);
    z-index: 0;

    @include media(">=phone") {
      width: 90%;
      padding: fluid("phone", "tablet", 170px, 130px) 0
        fluid("phone", "tablet", 270px, 50px);
    }

    @include media(">=phone") {
      padding: rem-calc(130) 0 rem-calc(50);
    }

    @include media(">=desktop-small") {
      padding: fluid("desktop-small", "desktop-xlarge", 130px, 235px) 0
        rem-calc(55);
    }

    @include media(">=desktop-xlarge") {
      padding: rem-calc(235) 0 rem-cacl(55);
    }
  }

  &__question {
    transform: scaleX(-1);
    position: absolute;
    left: -20px;
  }

  &__content {
    @include media(">=phone") {
      width: 60%;
      max-width: rem-calc(965);
    }
  }

  &__headline {
    font-size: rem-calc(40);
    font-family: $font-bingo-bold-trial;
    font-weight: 900;
    line-height: 1.2;
    color: $color-gray-100;

    @include media(">=phone-medium") {
      font-size: fluid("phone-medium", "phone", 40px, 45px);
    }

    @include media(">=phone") {
      font-size: rem-calc(45);
    }

    @include media(">=desktop-small") {
      font-size: fluid("desktop-small", "desktop-xlarge", 45px, 75px);
    }

    @include media(">=desktop-xlarge") {
      font-size: rem-calc(75);
    }
  }

  &__text {
    margin-top: rem-calc(18);
    font-size: rem-calc(14);
    font-family: $font-bingo-bold-trial;
    line-height: 1.1;
    color: $color-gray-100;

    @include media(">=phone-medium") {
      font-size: fluid("phone-medium", "phone", 14px, 16px);
    }

    @include media(">=phone") {
      font-size: rem-calc(16);
    }

    @include media(">=tablet") {
      font-size: fluid("tablet", "desktop-small", 16px, 20px);
    }

    @include media(">=desktop-small") {
      font-size: fluid("desktop-small", "desktop-xlarge", 20px, 32px);
    }

    @include media(">=desktop-xlarge") {
      font-size: rem-calc(32);
    }
  }

  .info {
    margin-top: rem-calc(25);

    @include media(">=phone-medium") {
      margin-top: fluid("phone-medium", "phone", 25px, 35px);
    }

    @include media(">=phone") {
      margin-top: rem-calc(35);
    }

    @include media(">=tablet") {
      margin-top: fluid("tablet", "desktop-small", 35px, 40px);
    }

    @include media(">=desktop-small") {
      margin-top: fluid("desktop-small", "desktop-xlarge", 40px, 70px);
      font-size: rem-calc(40);
    }

    @include media(">=desktop-xlarge") {
      margin-top: rem-calc(70);
    }

    &__item {
      font-size: rem-calc(18);
      font-family: $font-bingo-bold-trial;
      font-weight: 900;
      color: $color-white-100;
      text-transform: uppercase;

      @include media(">=phone-medium") {
        font-size: fluid("phone-medium", "phone", 18px, 25px);
      }

      @include media(">=phone") {
        font-size: rem-calc(25);
      }

      @include media(">=tablet") {
        font-size: fluid("tablet", "desktop-small", 25px, 32px);
      }

      @include media(">=desktop-small") {
        font-size: fluid("desktop-small", "desktop-xlarge", 32px, 50px);
      }

      @include media(">=desktop-xlarge") {
        font-size: rem-calc(50);
      }

      &:not(:first-child) {
        margin-top: rem-calc(8);

        @include media(">=phone-medium") {
          margin-top: fluid("phone-medium", "phone", 8px, 12px);
        }

        @include media(">=phone") {
          margin-top: rem-calc(12);
        }

        @include media(">=desktop-small") {
          margin-top: fluid("desktop-small", "desktop-xlarge", 12px, 16px);
        }

        @include media(">=desktop-xlarge") {
          margin-top: rem-calc(16);
        }
      }

      &:nth-child(2) {
        padding-left: rem-calc(10);
      }

      &:nth-child(3) {
        padding-left: rem-calc(20);
      }

      &--blue {
        span {
          color: $color-blue-100;
        }
      }

      span {
        margin-right: rem-calc(5);
      }
    }
  }

  .backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &__figure {
      position: absolute;
      height: auto;

      &[figure-id="1"] {
        top: 0;
        right: rem-calc(-15);
        width: rem-calc(235);
        z-index: 1;

        @include media(">=phone-xsmall") {
          right: fluid("phone-xsmall", "phone-medium", -15px, 0px);
          width: fluid("phone-xsmall", "phone-medium", 235px, 310px);
        }

        @include media(">=phone-medium") {
          top: fluid("phone-medium", "phone", 0px, -20px);
          width: fluid("phone-medium", "phone", 310px, 350px);
        }

        @include media(">=phone") {
          top: fluid("phone", "tablet", -20px, -50px);
          right: fluid("phone", "tablet", 0px, 150px);
          width: fluid("phone", "tablet", 350px, 400px);
        }

        @include media(">=tablet") {
          top: rem-calc(-50);
          right: fluid("tablet", "desktop-small", 150px, 220px);
          width: rem-calc(400);
        }

        @include media(">=desktop-small") {
          right: fluid("desktop-small", "desktop-xlarge", 220px, 380px);
          width: fluid("desktop-small", "desktop-xlarge", 400px, 650px);
        }

        @include media(">=desktop-xlarge") {
          right: rem-calc(380);
          width: rem-calc(650);
        }
      }

      &[figure-id="2"] {
        bottom: rem-calc(20);
        right: 0;
        width: 100%;
        max-width: rem-calc(375);
        z-index: 1;

        @include media(">=phone-medium") {
          max-width: fluid("phone-medium", "phone", 375px, 400px);
        }

        @include media(">=phone") {
          max-width: rem-calc(400);
        }

        @include media(">=tablet") {
          max-width: fluid("tablet", "desktop-small", 400px, 450px);
        }

        @include media(">=desktop-small") {
          max-width: fluid("desktop-small", "desktop-xlarge", 450px, 790px);
        }

        @include media(">=desktop-xlarge") {
          max-width: rem-calc(790);
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
