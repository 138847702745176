body {
	font-size: rem-calc(16);
	font-weight: 400;
	overflow-x: hidden;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.scroll-lock {
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}

img.lazyload:not([src]) {
	visibility: hidden;
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

img {
	max-width: 100%;
	height: auto;
}

/** Hide template elements. */
[class*="--tpl"] {
	display: none !important;
}
