.btn-email {
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: $color-white-100;
  background-color: $color-red2-100;
  transition: all 0.2s $ease-stn;
  box-shadow: 0 0 0 black, 0 0 0 rgb(17, 17, 17), inset 0 0 0 rgb(17, 17, 17),
    inset -5px -5px 5px rgba(7, 7, 7, 0.779);
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  @include media(">=phone-medium") {
    height: auto;
  }

  @include media("<=phone") {
    height: 30px;
    font-size: 15px;
    width: 100px;
    border-radius: 10px;
    position: relative;
    left: 50;
  }

  @include media(">=tablet") {
    font-size: rem-calc(12);
    padding: 10px;
  }

  @include media(">=desktop-small") {
    font-size: fluid("desktop-small", "desktop-xlarge", 12px, 16px);
  }

  @include media(">=desktop-xlarge") {
    font-size: rem-calc(16);
    width: 10px;
  }

  @include media(">=ipad-air") {
    font-size: rem-calc(16);
    width: 100px;
    padding: 10px;
  }

  &--red {
    background-color: $color-red2-100;
  }
}

.btn-calendar {
  background-color: $color-purple-100;
  color: $color-white-100;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s $ease-stn;
  box-shadow: inset -5px -5px 5px rgba(7, 7, 7, 0.918);
  justify-content: center;
  cursor: pointer;
  align-items: center;
  height: 30px;
  position: relative;
  border-radius: 5px;

  @include media(">=phone-medium") {
    height: auto;
    width: 100%;
    
  }

  @include media("<=phone") {
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    position: relative;
    height: 50px;
    padding: 10px;
    width: 100%; 
  }

  @include media(">=tablet") {
    font-size: rem-calc(12);
    padding: 10px;
  }

  @include media(">=desktop-small") {
    width: 300px;
  }

  @include media(">=desktop-xlarge") {
    font-size: rem-calc(16);
    width: 300px;
  }

  @include media(">=ipad-air") {
    font-size: rem-calc(16);
    width: 300px;
    padding: 10px;
  }

  @include media(">=ipad-mini") {
    width: auto;
    padding: 10px;
  }

  @include media(">=another-desktop") {
  }

  &--red {
    background-color: $color-red2-100;
  }
}

.btn-calendar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff2b39;
  height: 8px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 100%;
  box-shadow: inset 0px -2px 3.5px black;
  opacity: 0.9;
}
