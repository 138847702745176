// Absoulte Colors
$color-white: #fff;
$color-black: #000000;

// Primary Colors
$color-bg-100: #1a1a1a;
$color-gray-100: #1a1a1a;
$color-black-100: #1a1a1a;
$color-purple-100: #8D7DE4;
$color-white-100: #f8f5e3;
$color-blue-100: #6AE8F9;
$color-red-100: #FF2B39;
$color-green-100: #00b695;
$color-yellow-100: #ffb900;
$color-red2-100: #e50946;

// Fonts
$font-neue-machina: "Neue Machina", sans-serif;
$font-bingo-bold-trial: "Bingo Bold Trial", sans-serif; 

// Easing
$ease-stn: cubic-bezier(0.4, 0, 0.2, 1); // Standard easing.
$ease-emp: cubic-bezier(0.3, 0, 0, 1); // Emphasized easing.
$ease-smt: cubic-bezier(0.6, 0, 0, 1); // Super smooth easing.
$ease-dec: cubic-bezier(0, 0, 0.2, 1); // Decelerated easing (when entering).
$ease-acc: cubic-bezier(0.4, 0, 1, 1); // Accelerated easing (when exiting).

// Z-Indexes
$z-indexes: (
  "skip-link",
  "modal",
  "header",
  "mobile-menu",
  "mega-menu",
  "floating-cta"
);

//images
$btn--primary: "../assets/blue_button.png";
