.counter-banner .shadows {
  position: relative;
  &__shadow {
    position: absolute;
    height: auto;
    z-index: 1;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &[shadow-id="1"] {
      z-index: 0;
      width: 200px;
      left: 80%;
      bottom: -140px;
      position: relative;

      @include media(">=phone-xsmall") {
        left: 85%;
      }

      @include media(">=phone-medium") {
        left: 90%;
      }

      @include media(">=phone") {
        left: 90%;
      }

      @include media(">=desktop-small") {
        left: 90%;
        bottom: -140px;
        width: 250px;
      }

      @include media(">=desktop-xlarge") {
        left: 90%;
        bottom: -105px;
        width: 250px;
      }

      @include media(">=desktop-mlarge") {
        left: 90%;
        bottom: -105px;
        width: 250px;
      }
    }

    &[shadow-id="2"] {
      z-index: -1;
      width: 60px;
      left: 85%;
      bottom: -120px;
      @include media(">=phone-xsmall") {
        left: 85%;
        bottom: -130px;
        margin-bottom: 30px;
        width: 100px;
      }

      @include media(">=phone-medium") {
        left: 90%;
        width: 100px;
        bottom: -150px;
      }

      @include media(">=phone") {
        left: 85%;
        bottom: -140px;
        width: 100px;
      }

      @include media(">=tablet") {
        left: 85%;
        bottom: -140px;
        width: 100px;
      }

      @include media(">=desktop-small") {
        bottom: -150px;
        width: 100px;
        left: 90%;
      }

      @include media(">=desktop-xlarge") {
        left: 90%;
        bottom: -130px;
        width: 100px;
      }
    }

    &[shadow-id="3"] {
      z-index: 0;
      width: 20px;
      left: 93%;
      bottom: 80px;
      position: relative;

      @include media(">=phone-xsmall") {
        left: 80%;
        bottom: 80px;
      }

      @include media(">=phone-medium") {
        left: 80%;
        bottom: 80px;
      }

      @include media(">=phone") {
        left: 92%;
        bottom: 100px;
      }

      @include media(">=tablet") {
        left: 90%;
      }

      @include media(">=desktop-small") {
        left: 90%;
        width: 30px;
        bottom: 150px;
      }

      @include media(">=desktop-xlarge") {
        left: 95%;
      }
    }

    &[shadow-id="4"] {
      z-index: 0;
      width: 30px;
      left: 85%;
      bottom: 160px;

      @include media(">=phone-xsmall") {
        left: 90%;
      }

      @include media(">=phone-medium") {
        left: 95%;
      }

      @include media(">=phone") {
        left: 95%;
      }

      @include media(">=tablet") {
        left: 87%;
      }

      @include media(">=desktop-small") {
        left: 85%;
        width: 40px;
        bottom: 230px;
      }

      @include media(">=desktop-xlarge") {
        left: 95%;
        bottom: 95px;
      }
    }

    &[shadow-id="5"] {
      z-index: 0;
      width: 510px;
      top: 100px;
      left: -250px;
      position: absolute;

      @include media(">=phone-xsmall") {
        left: -350px;
        top: 130px;
        width: 700px;
      }
      @include media(">=desktop-mlarge") {
        width: 900px;
        left: -450px;
        top: -5px;
      }
      @include media(">=desktop-small") {
        width: 1100px;
        left: -550px;
        top: 90px;
      }
      @include media(">=another-desktop") {
        width: 1100px;
        left: -550px;
        top: 50px;
      }
    }

    &[shadow-id="6"] {
      z-index: 0;
      width: 300px;
      top: 850px;
      right: -220px;

      @include media(">=phone-xsmall") {
        right: -220px;
        top: 750px;
      }

      @include media(">=phone-medium") {
        top: 900px;
      }

      @include media(">=phone-medium") {
        top: 700px;
      }

      @include media(">=phone") {
        top: 500px;
      }

      @include media(">=tablet") {
        top: 500px;
      }

      @include media(">=desktop-small") {
        right: -200px;
        top: 600px;
      }

      @include media(">=desktop-xlarge") {
        left: 95%;
        top: 900px;
      }
    }

    &[shadow-id="7"] {
      z-index: 0;
      width: 100px;
      left: 80%;
      top: 1000px;

      @include media(">=phone-xsmall") {
        left: 85%;
        top: 900px;
      }

      @include media(">=phone-medium") {
        left: 93%;
        top: 850px;
      }

      @include media(">=phone") {
        left: 94%;
        top: 650px;
      }

      @include media(">=tablet") {
        left: 94%;
        top: 650px;
      }

      @include media(">=desktop-small") {
        left: 95%;
        top: 770px;
      }

      @include media(">=desktop-xlarge") {
        left: 95%;
        top: 1100px;
      }
    }

    &[shadow-id="8"] {
      z-index: 0;
      position: absolute;
      width: 300px;
      top: -700px;
      left: -170px;

      @include media(">=phone-xsmall") {
        left: -170px;
        top: -700px;
        width: 250px;
      }

      @include media(">=phone-medium") {
        left: -150px;
        top: -740px;
      }

      @include media(">=phone") {
        left: -150px;
        top: -400px;
      }

      @include media(">=tablet") {
        left: -70px;
        top: -530px;
      }

      @include media(">=desktop-small") {
        top: -490px;
        width: 450px;
      }

      @include media(">=desktop-xlarge") {
        left: 50px;
        top: 100px;
      }

      @include media(">=desktop-mlarge") {
        left: -180px;
        top: -400px;
      }
      @include media(">=surface-pro-7") {
        left: -260px;
        top: -400px;
      }
      @include media(">=galaxy-fold") {
      }
    }

    &[shadow-id="9"] {
      z-index: 6;
      position: relative;
      width: 20px;
      top: -950px;
      left: -1px;

      @include media(">=phone-xsmall") {
        left: 5px;
        top: -990px;
      }

      @include media(">=phone-medium") {
        left: 5px;
        top: -950px;
      }

      @include media(">=phone") {
        left: 10px;
        top: -730px;
        width: 30px;
      }

      @include media(">=tablet") {
        left: 10px;
        top: -700px;
      }

      @include media(">=desktop-small") {
        left: 10px;
        top: -800px;
      }

      @include media(">=desktop-xlarge") {
        left: 10px;
        top: -600px;
      }
      @include media(">=desktop-mlarge") {
        width: 20px;
        left: 10px;
        top: -590px;
      }
      @include media(">=surface-pro-7") {
        left: 30px;
        width: 25px;
      }
      @include media(">=another-desktop") {
        left: 10px;
        top: -750px;
      }
      @include media(">=galaxy-fold") {
      }
    }

    &[shadow-id="10"] {
      z-index: 0;
      position: absolute;
      width: 250px;
      left: -100px;
      top: 890px;

      @include media(">=phone-xsmall") {
        left: -100px;
        top: 950px;
      }

      @include media(">=phone-medium") {
        left: -100px;
        top: 890px;
      }

      @include media(">=phone") {
        left: -110px;
        top: 690px;
      }

      @include media(">=tablet") {
      }

      @include media(">=desktop-small") {
        left: -100px;
        top: 910px;
      }

      @include media(">=desktop-xlarge") {
        left: -100px;
        top: 700px;
      }

      @include media(">=desktop-mlarge") {
        width: 300px;
        left: -120px;
        top: 690px;
      }

      @include media(">=1600px") {
        top: 1090px;
      }
      @include media(">=another-desktop") {
      }
    }
    &[shadow-id="11"] {
      z-index: 0;
      position: absolute;
      width: 60px;
      left: 10px;
      top: 955px;

      @include media(">=phone-xsmall") {
        left: 10px;
        top: 940px;
      }

      @include media(">=phone-medium") {
        left: 10px;
        top: 940px;
      }

      @include media(">=phone") {
        left: 0px;
        top: 720px;
      }

      @include media(">=tablet") {
        left: 1px;
        top: 770px;
      }

      @include media(">=desktop-small") {
        left: 10px;
        top: 990px;
      }

      @include media(">=desktop-xlarge") {
        left: 10px;
        top: 790px;
      }
      @include media(">=desktop-mlarge") {
        width: 60px;
        top: 800px;
      }
      @include media(">=1600px") {
        top: 1180px;
      }
      @include media(">=another-desktop") {
      }
    }
    &[shadow-id="12"] {
      z-index: 0;
      position: absolute;
      width: 12px;
      left: 10px;
      top: -700px;

      @include media(">=phone-xsmall") {
        left: 20px;
        top: -675px;
      }

      @include media(">=phone-medium") {
        left: 10px;
        top: -540px;
      }

      @include media(">=phone") {
        left: 20px;
        top: -410px;
        width: 20px;
      }

      @include media(">=tablet") {
        left: 20px;
        top: -553px;
      }

      @include media(">=desktop-small") {
        left: 20px;
        top: -520px;
        width: 20px;
      }

      @include media(">=desktop-xlarge") {
        left: 10px;
        top: -590px;
      }
      @include media(">=surface-pro-7") {
        top: -400px;
        left: 45px;
      }
      @include media(">=desktop-mlarge") {
        width: 20px;
        top: -398px;
        left: 40px;
      }
      @include media(">=another-desktop") {
        left: 20px;
        top: -350px;
      }
    }
    &[shadow-id="13"] {
      z-index: 0;
      position: absolute;
      width: 12px;
      left: 15px;
      top: -710px;

      @include media(">=phone-xsmall") {
        left: 25px;
        top: -685px;
      }

      @include media(">=phone-medium") {
        left: 15px;
        top: -550px;
      }

      @include media(">=phone") {
        left: 30px;
        top: -425px;
        width: 20px;
      }

      @include media(">=tablet") {
        left: 25px;
        top: -560px;
      }

      @include media(">=desktop-small") {
        left: 25px;
        top: -510px;
      }

      @include media(">=desktop-xlarge") {
        left: 15px;
        top: -600px;
      }

      @include media(">=surface-pro-7") {
        top: -415px;
        left: 55px;
        width: 20px;
      }
      @include media(">=desktop-mlarge") {
        width: 20px;
        top: -415px;
        left: 50px;
      }
      @include media(">=another-desktop") {
        left: 30px;
        top: -370px;
        
      }
    }
    &[shadow-id="14"] {
      z-index: 10;
      position: relative;
      width: 50px;
      top: 200px;
      left: 5px;

      @include media(">=phone-xsmall") {
        left: 20px;
        top: -710px;
      }

      @include media(">=phone-medium") {
        left: 15px;
        top: -550px;
      }

      @include media(">=phone") {
        left: 20px;
        top: -510px;
      }

      @include media(">=tablet") {
        left: 25px;
        top: -560px;
      }

      @include media(">=desktop-small") {
        top: -510px;
      }

      @include media(">=desktop-xlarge") {
        top: 600px;
      }
    }
    &[shadow-id="15"] {
      z-index: 0;
      position: absolute;
      width: 500px;
      left: 0px;

      @include media(">=galaxy-fold") {
        top: -190px;
      }

      @include media(">=another-desktop") {
        left: 580px;
      }

      @include media(">=phone-xsmall") {
        left: 50px;
      }

      @include media(">=phone-medium") {
        left: 50px;
        top: -120px;
      }

      @include media(">=phone") {
        left: 500px;
        top: 0px;
      }

      @include media(">=tablet-small") {
        left: 700px;
        top: -120px;
      }

      @include media(">=surface-duo") {
        left: 60%;
        top: -200px;
      }

      @include media(">=tablet") {
        left: 0px;
        margin-left: 200px;
        top: -110px;
      }

      @include media(">=surface-pro-7") {
        top: 150px;
      }

      @include media(">=desktop-small") {
        width: 800px;
        top: -260px;
        left: 70%;
        
      }

      @include media(">=desktop-xlarge") {
        width: 800px;
        left: 580px;
        top: 90px;
      }

      @include media(">=desktop-mlarge") {
        left: 50%;
        
      }

      @include media(">=another-desktop") {
        top: 70px;
        width: 800px;
        left: 90%;
      }

      @include media(">=desktop-mlarge") {
        width: 800px;
        left: 90%;
        top: 90px;
      }

      @include media(">=ipad-air") {
        left: 50%;
      }

      @include media(">=ipad-mini") {
        width: 800px;
        top: -280px;
        left: 60%;
      }

      @include media(">1900px") {
        width: 800px;
        margin-right: -50px;
      }
    }
  }
}
