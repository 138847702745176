/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus. As per focus-visible polyfill instructions:
  https://github.com/WICG/focus-visible
*/
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

.show-for-sr {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.skip-link {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	padding: rem-calc(13) rem-calc(13) rem-calc(10);
	font-size: rem-calc(12);
	color: #000;
	background: white;
	opacity: 0;
	font-weight: bold;
	text-transform: uppercase;
	pointer-events: none;

	&:focus {
		top: 20px;
		left: 20px;
		width: auto;
		height: auto;
		padding: 20px 30px;
		margin: 0;
		overflow: visible;
		background-color: #f0f0f0;
		opacity: 1;
		clip: auto;
	}
}