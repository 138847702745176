.landing .counter-banner {
  position: relative;
  background-color: $color-bg-100;
  z-index: 1;
  align-items: center;
  justify-content: center;

  &__pictureContainer{
    position: relative;
    @include media(">=desktop-small"){
      right: 0px;
    }
    @include media(">=desktop-small"){
      right: 0px;
    }
    @include media(">=another-desktop"){
      right: 150px;
    }
    @include media(">=phone"){
      bottom: 50px;
      
    }
    @include media(">=tablet"){
      bottom: 50px;
    }
    @include media(">=phone-xsmall"){
      bottom: 50px;
      right: 20px;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    max-width: 2000px;
    display: block;
    top: 150px;

    @include media(">=tablet") {
    }
    @include media(">=desktop-small") {
    }
    @include media(">=desktop-mlarge") {
    }
    @include media(">=desktop-large") {
    }
    @include media(">=desktop-xlarge") {
    }
    @include media(">=another-desktop") {
    }
    @include media(">=phone-xsmall") {
    }

    @include media(">=surface-pro-7") {
    }

    @include media(">=phone-medium") {
    }

    @include media(">=phone") {
    }
    @include media("<=ipad-air") {
    }
    @include media("<=ipad-mini") {
    }
  }

  &__wrapper {
    @extend %container;

    position: relative;
    z-index: 1;

    @include media(">=another-desktop") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=phone-xsmall") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=phone") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=tablet") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=desktop-small") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=desktop-mlarge") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=desktop-xlarge") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__headings {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include media(">=phone-xsmall") {
      gap: fluid("phone-xsmall", "phone-medium", 10px, 20px);
    }

    @include media(">=phone-medium") {
      gap: fluid("phone-medium", "phone", 20px, 25px);
      align-items: center;
    }

    @include media(">=phone") {
      grid-template-columns: 1fr 1fr;
      gap: fluid("phone", "desktop-small", 5px, 20px);
    }

    @include media(">=desktop-small") {
      grid-template-columns: 1fr 1fr;
    }

    @include media(">=desktop-mlarge") {
      grid-template-columns: 1fr 1fr;
    }
  }

  .headings-left {
    &__top {
      display: none;
      font-size: rem-calc(22);
      font-family: $font-bingo-bold-trial;
      font-weight: 900;
      color: $color-white-100;

      @include media(">=phone-medium") {
        display: block;
        font-size: fluid("phone-medium", "phone", 22px, 26px);
      }

      @include media(">=phone") {
        font-size: rem-calc(20);
      }

      @include media(">=desktop-small") {
        margin-bottom: fluid("desktop-small", "desktop-xlarge", 10px, 15px);
        font-size: fluid("desktop-small", "desktop-xlarge", 20px, 32px);
      }

      @include media(">=desktop-xlarge") {
        margin-bottom: rem-calc(15);
        font-size: rem-calc(32);
      }
    }

    &__media {
      width: 100%;
      height: rem-calc(80);
      position: relative;

      @include media(">=phone-xsmall") {
        height: fluid("phone-xsmall", "phone-medium", 80px, 135px);
      }

      @include media(">=phone-medium") {
        height: fluid("phone-medium", "phone", 135px, 172px);
      }

      @include media(">=phone") {
        height: fluid("phone", "tablet", 95px, 126px);
      }

      @include media(">=tablet") {
        height: fluid("tablet", "desktop-small", 126px, 140px);
      }

      @include media(">=desktop-small") {
        height: fluid("desktop-small", "desktop-xlarge", 100px, 165px);
      }

      @include media(">=desktop-xlarge") {
        height: rem-calc(165);
      }
    }

    &__picture {
      display: block;
      width: 400px;
      height: auto;
      position: relative;

      @include media(">=galaxy-fold") {
        padding-top: 0px;

      }

      @include media(">=phone-medium") {
        padding-top: 0px;

      }

      @include media(">=phone") {
        padding-top: 0px;
        bottom: 50px;


      }

      @include media(">=tablet") {
        padding-top: 60px;

      }

      @include media(">=desktop-small") {
        padding-top: 60px;

      }

      @include media(">=desktop-xlarge") {
        padding-top: 60px;

      }
      @include media(">=another-desktop") {
        padding-top: 60px;

      }
    }

    &__image {
      width: 600px;
      height: 100%;
      object-fit: cover;
      @include media("<=tablet") {
        width: 300px;
        margin-left: 0 auto;
        margin-right: 0 auto;
      }
    }

    &__bottom {
      @extend %title-filter;
      font-weight: bold;
      margin-left: 5px;
      margin-top: rem-calc(15);
      font-size: rem-calc(16);
      width: max-content;
      padding: 5px;
      font-family: $font-bingo-bold-trial;
      color: $color-white-100;
      background: rgb(255, 43, 57);
      background: linear-gradient(
        90deg,
        rgba(255, 43, 57, 1) 10%,
        rgba(156, 115, 206, 1) 42%,
        rgba(141, 125, 228, 1) 50%,
        rgba(19, 188, 204, 1) 78%,
        rgba(0, 238, 255, 1) 100%
      );

      @include media(">=phone-xsmall") {
        font-size: fluid("phone-xsmall", "phone-medium", 16px, 22px);
      }

      @include media(">=phone-medium") {
        padding-right: rem-calc(10);
        padding-left: 0;
        font-size: fluid("phone-medium", "phone", 22px, 26px);
        text-align: right;
      }

      @include media(">=phone") {
        font-size: rem-calc(20);
      }

      @include media(">=desktop-small") {
        margin-top: fluid("desktop-small", "desktop-xlarge", 15px, 25px);
        font-size: ("desktop-small", "desktop-xlarge", 20px, 32px);
      }

      @include media(">=desktop-xlarge") {
        margin-top: rem-calc(25);
        font-size: rem-calc(32);
      }

      span {
        display: block;
      }
    }

    &__container {
      position: relative;
      width: 100%;
      margin-left: 0 auto;
      margin-right: 0 auto;
    }

    &__contest {
      background-color: $color-purple-100;
      width: 250px;
      margin-top: 10px;
      margin-left: 65px;
      padding: 10px;
      font-size: 30px;
      border-top: 10px solid $color-blue-100;
      color: $color-gray-100;
      font-family: $font-bingo-bold-trial;
      @include media("<=phone") {
        margin-left: 35px;
        width: 150px;
        font-size: 15px;
      }
    }

    &__awards {
      background-color: $color-red-100;
      width: 450px;
      display: flex;
      justify-content: center;
      border-top: 7px solid $color-blue-100;
      position: relative;
      left: 100px;
      bottom: 10px;
      padding: 5px;
      font-family: $font-bingo-bold-trial;
      @include media("<=phone") {
        left: 50px;
        width: 250px;
        bottom: 1px;
      }
    }

    &__awards h3 {
      align-items: center;
      font-size: 30px;
      color: $color-white-100;
      letter-spacing: 1px;
      @include media("<=phone") {
        font-size: 15px;
      }
    }

    &__awards span {
      color: $color-blue-100;
      font-size: 50px;
      font-weight: 600;
      @include media("<=phone") {
        font-size: 20px;
      }
    }
  }

  .headings-right {
    &__title {
      @extend %title-filter;

      font-size: rem-calc(16);
      font-family: $font-bingo-bold-trial;
      font-weight: 300;
      color: $color-white-100;
      position: relative;
      @include media(">=phone-xsmall") {
        font-size: fluid("phone-xsmall", "phone-medium", 16px, 30px);
      }

      @include media(">=phone-medium") {
        font-size: fluid("phone-medium", "phone", 30px, 38px);
      }

      @include media(">=phone") {
        font-size: fluid("phone", "tablet", 30px, 38px);
      }

      @include media(">=tablet") {
        font-size: rem-calc(38);
      }

      @include media(">=desktop-small") {
        font-size: fluid("desktop-small", "desktop-large", 38px, 48px);
        line-height: 1;
      }

      @include media(">=desktop-large") {
        font-size: fluid("desktop-large", "desktop-xlarge", 48px, 54px);
      }

      @include media(">=desktop-xlarge") {
        font-size: rem-calc(54);
      }
    }

    &__subtitle {
      margin-top: rem-calc(5);
      font-size: rem-calc(16);
      font-family: $font-bingo-bold-trial;
      font-weight: 900;
      color: $color-blue-100;

      @include media(">=phone-xsmall") {
        margin-top: fluid("phone-xsmall", "phone-medium", 5px, 10px);
        font-size: fluid("phone-xsmall", "phone-medium", 16px, 25px);
      }

      @include media(">=phone-medium") {
        margin-top: rem-calc(10);
        font-size: fluid("phone-medium", "phone", 25px, 32px);
      }

      @include media(">=phone") {
        font-size: fluid("phone", "tablet", 26px, 32px);
      }

      @include media(">=tablet") {
        font-size: rem-calc(36);
      }

      @include media(">=desktop-small") {
        font-size: fluid("desktop-small", "desktop-xlarge", 36px, 54px);
      }

      @include media(">=desktop-xlarge") {
        font-size: rem-calc(54);
      }
    }

    @include media(">=phone") {
      font-size: fluid("phone-xsmall", "phone", 20px, 25px);
    }

    @include media(">=desktop-xlarge") {
      font-size: rem-calc(50);
    }
  }

  .container-social {
    @include media(">desktop-small") {
    }
  }

  .social {
    background-color: $color-red2-100;
    z-index: 10;
    width: 50px;
    height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    box-shadow: inset -5px -5px 5px rgba(7, 7, 7, 0.652);
    @include media("<=phone") {
      position: fixed;
      flex-direction: row;
      justify-content: space-evenly;
      border-top: 3px solid $color-blue-100;
      height: 70px;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 0;
    }
    @include media("<=ipad-air") {
      position: fixed;
      height: 70px;
      bottom: 0;
      flex-direction: row;
      justify-content: space-evenly;
      border-top: 3px solid $color-blue-100;

      left: 0;
      width: 100%;
      z-index: 0;
    }
    @include media("<=ipad-mini") {
      position: fixed;
      height: 70px;
      bottom: 0;
      left: 0;
      flex-direction: row;
      justify-content: space-evenly;
      border-top: 3px solid $color-blue-100;
      width: 100%;
      z-index: 0;
    }
    @include media("<=tablet") {
      position: fixed;
      height: 70px;
      bottom: 0;
      left: 0;
      flex-direction: row;
      justify-content: space-evenly;
      border-top: 3px solid $color-blue-100;
      width: 100%;
      z-index: 0;
    }
    @include media("<=desktop-small") {
      position: fixed;
      height: 70px;
      bottom: 0;
      left: 0;
      flex-direction: row;
      justify-content: space-evenly;
      border-top: 3px solid $color-blue-100;
      width: 100%;
      z-index: 0;
    }
  }

  .icon {
    background-color: $color-bg-100;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    width: 30px;
    height: 30px;
    .box-icon {
      width: 20px;
    }
  }

  .social-top {
    background-color: $color-blue-100;
    width: 50px;
    height: 10px;
    position: relative;
    left: 50%;
    box-shadow: inset 0px -3px 5px black;
    @include media("<=phone") {
      display: none;
    }
    @include media("<=ipad-air") {
      display: none;
    }
    @include media("<=ipad-mini") {
      display: none;
    }
    @include media("<=tablet") {
      display: none;
    }
    @include media("<=desktop-small") {
      display: none;
    }
  }
  .year {
    background-color: $color-blue-100;
    display: flex;
    width: 70px;
    justify-content: flex-end;
    padding: 10px;
    border-top: 10px solid $color-purple-100;
    color: $color-white-100;
    font-family: $font-bingo-bold-trial;
    font-size: 25px;
    position: relative;
    top: 100px;
    left: 50%;
    z-index: -1;
    @include media("<=phone") {
      display: none;
    }
  }

  .counter-social {
    padding-right: 200px;
    @include media(">=phone") {
      padding-right: 0px;
    }
    @include media(">=tablet") {
      padding-right: 200px;
    }
    @include media(">=phone-xsmall") {
      padding-right: 0px;
    }
    @include media(">=phone-small") {
      padding-right: 0px;
    }
    @include media(">=galaxy-fold") {
      padding-right: 0px;
    }
  }

  .counter-box {
    position: relative;
    margin: rem-calc(55) auto 0;
    width: 100%;

    @include media(">=phone") {
      width: 100%;
    }

    @include media(">=desktop-small") {
      margin-top: fluid("desktop-small", "desktop-xlarge", 55px, 110px);
      display: flex;
      justify-content: space-between;
    }

    @include media(">=desktop-xlarge") {
      margin-top: rem-calc(110);
      display: flex;
    }

    @include media(">=desktop-mlarge") {
    }

    @include media(">=tablet") {
    }

    @include media(">=desktop-medium") {
    }

    &__counter {
      @include media(">=phone") {
      }
    }

    &__logo {
      position: absolute;
      top: rem-calc(-120);
      right: rem-calc(-5);
      width: rem-calc(75);
      height: auto;

      @include media(">=phone-xsmall") {
        top: fluid("phone-xsmall", "phone-medium", -120px, -170px);
        right: fluid("phone-xsmall", "phone-medium", -5px, -20px);
        width: fluid("phone-xsmall", "phone-medium", 75px, 100px);
      }

      @include media(">=phone-medium") {
        top: rem-calc(-140);
        right: fluid("phone-medium", "phone", -50px, -105px);
        width: fluid("phone-medium", "phone", 100px, 120px);
      }

      @include media(">=phone") {
        top: rem-calc(-180);
        right: fluid("phone", "tablet", 120px, 180px);
        width: fluid("phone", "tablet", 110px, 120px);
      }

      @include media(">=tablet") {
        top: fluid("tablet", "desktop-small", -180px, -160px);
        right: fluid("tablet", "desktop-small", 180px, 200px);
        width: fluid("tablet", "desktop-small", 120px, 130px);
      }

      @include media(">=desktop-small") {
        top: fluid("desktop-small", "desktop-xlarge", -190px, -300px);
        right: fluid("desktop-small", "desktop-xlarge", 230px, 310px);
        width: fluid("desktop-small", "desktop-xlarge", 140px, 210px);
      }

      @include media(">=desktop-xlarge") {
        top: rem-calc(-300);
        right: rem-calc(310);
        width: rem-calc(210);
      }

      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@import "./counter-banner/get-email-reminder";
@import "./counter-banner/shadow";
